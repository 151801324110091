import Router from "next/router";
import moment from "moment";
import axios from "axios";

export function getQuery(queryName) {
  if(typeof window === 'undefined') return null;
  // console.log("window.location.search", window.location.search)
  var urlParams = new URLSearchParams(window.location.search);
  var query = urlParams.get(queryName);
  return query;
}

export async function searchDeliveries(searchterm, queries = {}) {
  let response = {};
  let url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/delivery/searchDeliveries/${searchterm}?platform=super_admin_panel`;

  const request = await axios.get(url, {
    validateStatus: false,
    params: queries,
  });

  if (request.data.code === 200) {
    response.success = true;
    response.data = request.data.responses;
    response.message = request.data.message;
  } else {
    response.success = false;
    response.data = null;
    response.message = request.data.message;
  }

  return response;
}

export async function searchDeliveriesCode(searchterm, queries = {}) {
  let response = {};
  let url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/delivery/search/delivery-code/${searchterm}?platform=super_admin_panel`;

  const request = await axios.get(url, {
    validateStatus: false,
    params: queries,
  });

  if (request.data.code === 200) {
    response.success = true;
    response.data = request.data.responses;
    response.message = request.data.message;
  } else {
    response.success = false;
    response.data = null;
    response.message = request.data.message;
  }

  return response;
}



export function formatDate(timestamp, format = "date") {
  var date = new Date(timestamp);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

  var year = date.getFullYear();

  var month = date.getMonth();

  var day = date.getDate();

  // Will display time in 10:30:23 format
  var formatted;

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (format == "time") {
    formatted = hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
    formatted = moment(timestamp).format("h:mm a ")
  } else if (format == "date") {
    formatted = months[month] + " " + day + ", " + year;
    formatted = moment(timestamp).format("Do MMM, YYYY")
  } else if (format == "date_time") {
    formatted = moment(timestamp).format("h:mm a ; Do MMM YYYY")
  } else {
    formatted = months[month] + " " + day + ", " + year;
  }

  return formatted;
}

export function navigate(page_name = "", query = null) {
  Router.push({
    pathname: `/${page_name}`,
    query: query,
  });
}





export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var sliceSize = 512;

  var byteCharacters = atob(dataURI);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {
    type: "image/jpeg",
  });

  return blob;
}


export async function getRunningDeliveries(agencyId) {
  var results = {};

  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/trip/getagencytrip/${agencyId}`;
  console.log("Requesting: ", url);
  const options = {
    method: "GET",
    cors: "no-cors",
    headers: {
      "Content-Type": "application/json",
      // 'x-access-token': token
    },
  };
  console.log("Options", options);

  const req = await fetch(url, options);
  console.log(req);
  const response = await req.json();
  console.log(response);

  if (response.code === 200) {
    results.success = true;
    results.message = response.message;
    results.data = response.responses;
  } else {
    results.success = false;
    results.message = response.message;
  }

  return results;
}

export async function getPendingDeliveries(agencyId) {
  var results = {};

  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/delivery/getassigneddeliveries/${agencyId}`;
  console.log("Requesting: ", url);
  const options = {
    method: "GET",
    cors: "no-cors",
    headers: {
      "Content-Type": "application/json",
      // 'x-access-token': token
    },
  };
  console.log("Options", options);

  const req = await fetch(url, options);
  console.log(req);
  const response = await req.json();
  console.log(response);

  if (response.code === 200) {
    results.success = true;
    results.message = response.message;
    results.data = response.responses;
  } else {
    results.success = false;
    results.message = response.message;
  }

  return results;
}

export async function getAllAgencies(pagenumber = 0, limits = 10) {
  var results = {};

  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/agency/allagencies/pagingation/21jkw21afsan124124wf/${pagenumber}/${limits}`;
  console.log("Requesting: ", url);

  const response = await axios.get(url, {
    validateStatus: false,
  });
  console.log(response);

  if (response.data.code === 200) {
    console.log(response);
    results.success = true;
    results.message = response.data.message;
    results.data = response.data.responses;
  } else {
    results.success = false;
    results.message = response.data.message;
  }

  return results;
}

export async function getAgencyAgents(agencyId) {
  var results = {};

  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/agent/getagencyagents/${agencyId}`;
  console.log("Requesting: ", url);
  const options = {
    method: "GET",
    cors: "no-cors",
    headers: {
      "Content-Type": "application/json",
      // 'x-access-token': token
    },
  };
  console.log("Options", options);

  const req = await fetch(url, options);
  console.log(req);
  const response = await req.json();
  console.log(response);

  if (response.code === 200) {
    results.success = true;
    results.message = response.message;
    results.data = response.responses;
  } else {
    results.success = false;
    results.message = response.message;
  }

  return results;
}

export async function assignDeliveryToAgent(data) {
  var results = {};
  console.log(data);
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/trip/assigndeliveriestoagent`;
  console.log("Requesting: ", url);
  const options = {
    method: "POST",
    cors: "no-cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  console.log("Options", options);

  const req = await fetch(url, options);
  console.log(req);
  const response = await req.json();
  console.log(response);

  if (response.code === 200) {
    results.success = true;
    results.message = response.message;
    results.data = response.responses;
  } else {
    results.success = false;
    results.message = response.message;
  }

  return results;
}

export async function endTrip(data) {
  var results = {};
  console.log(data);
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/delivery/enddelivery`;
  console.log("Requesting: ", url);

  try {
    const response = await axios.put(url, data);
    console.log(response);

    if (response.data.code === 200) {
      results.success = true;
      results.message = response.data.message;
      results.data = response.data.responses;
    } else {
      results.success = false;
      results.message = response.data.message;
    }

    return results;
  } catch (error) {
    console.error(error);
    results.success = false;
    results.message = "Failed. Try again";

    return results;
  }
}

export async function reassignDelivery(data) {
  var results = {};
  console.log(data);
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/delivery/assigntoagency`;
  console.log("Requesting: ", url);

  const response = await axios.post(url, data, {
    validateStatus: false,
  });
  console.log(response);

  if (response.data.code === 200) {
    results.success = true;
    results.message = response.data.message;
    results.data = response.data.responses;
  } else {
    results.success = false;
    results.message = response.data.message;
  }

  return results;
}








export async function getCountryData(name) {
  var response = {};

  const url = `https://restcountries.eu/rest/v2/name/${name}`;
  const options = {
    method: "GET",
    cors: "no-cors",
  };

  const request = await fetch(url, options);
  const results = await request.json();
  console.log(results);

  response = results[0];

  return response;
}





export async function reverseGeocoding(latLng) {
  let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`;
  var response = {};

  const options = {
    method: "GET",
    cors: "no-cors",
  };

  const request = await fetch(url, options);
  const results = await request.json();

  if (results.status === "OK") {
    response.success = true;
    response.data = results.results[0];
    response.message = "OK";
  } else {
    response.success = false;
    response.data = null;
    response.message = results.message;
  }

  return response;
}

/**
 * Implementing time ago
 * **************************************************************
 */

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} at ${hours}:${minutes}`;
  }

  if (hideYear) {
    //January 10, at 10:20
    return `${month} ${day}, at ${hours}:${minutes}`;
  }

  // January 10, 2017 at 10:20
  return `${month} ${day}, ${year} at ${hours}:${minutes}`;
}

// --- Main function
export function timeAgo(dateParam) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === "object" ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

  if (seconds < 5) {
    return "now";
  } else if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (seconds < 90) {
    return "about a minute ago";
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (isToday) {
    return getFormattedDate(date, "Today"); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, "Yesterday"); // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, false, true); // 10. January at 10:20
  }

  return getFormattedDate(date); // 10. January 2017. at 10:20
}

/**
 * Better method for making http requests
 * @param {string} url
 * @param {string} method
 * @param {Object} payload
 * @param {Object} options
 */
export async function httpRequest(
  url,
  method = "get",
  payload = null,
  options = {}
) {
  //console.log("Request URL: ", url)
  //console.log("Request Method: ", method)
  //console.log("Payload: ", payload)

  if (method.toLowerCase() === "get") {
    const req = await axios.get(url, {
      validateStatus: false,
      ...options,
    });
    const response = req.data;
    //console.log(response)
    return response;
  } else if (method.toLowerCase() === "post") {
    const req = await axios.post(url, payload, {
      validateStatus: false,
      headers: {
        "x-user-agent": "User Swoove Super-Admin-Panel 1.0.0",
      },
      ...options,
    });
    const response = req.data;
    //console.log(response)
    return response;
  } else if (method.toLowerCase() === "put") {
    const req = await axios.put(url, payload, {
      validateStatus: false,
      headers: {
        "x-user-agent": "User Swoove Super-Admin-Panel 1.0.0",
      },
      ...options,
    });
    const response = req.data;
    //console.log(response)
    return response;
  } else if (method.toLowerCase() === "delete") {
    const req = await axios.delete(url, {
      validateStatus: false,
      headers: {
        "x-user-agent": "User Swoove Super-Admin-Panel 1.0.0",
      },
      ...options,
    });
    const response = req.data;
    //console.log(response)
    return response;
  } else {
    return null;
  }
}

/**
 * Date to unix timestamp
 * @param {*} date
 */
export const DateToUnix = (date) => {
  console.log(date);
  let elapsed = parseInt((new Date(date).getTime() / 1000).toFixed(0));
  return elapsed;
};

/**
 * Date to ms
 * @param {*} date
 */
export const DateToMs = (date) => {
  // console.log(date)
  let milliseconds = new Date(date).getTime();
  return milliseconds;
};

/**
 * MS to date
 * @param {*} date
 */
export const MsToDate = (ms) => {
  var d = new Date(ms).toISOString().substr(0, 10);
  return d;
};

/**
 * Function to get delivery aggregate
 */
export const deliverySummaries = function (list = []) {
  Array.prototype.sum = function (prop) {
    var total = 0;
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop];
    }
    return total;
  };

  let response = {};

  //console.log(list)

  list &&
    list.length > 0 &&
    list.map((i) => {
      response[i.status] = {};
      response[i.status].totalCount = list
        .filter((x) => x.status === i.status)
        .sum("count");
      response[i.status].totalAgencyCharge = list
        .filter((x) => x.status === i.status)
        .sum("agency_charge");
      response[i.status].totalSwooveCharge = list
        .filter((x) => x.status === i.status)
        .sum("swoove_charge");
      response[i.status].mobileMoneyCount = list
        .filter(
          (x) =>
          x.status === i.status &&
          x.payment_status === "PAID" &&
          (x.payment_type === "Mobile_Money" ||
            x.payment_type === "MOBILE_MONEY")
        )
        .sum("count");

      response[i.status].cashCount = list
        .filter(
          (x) =>
          x.status === i.status &&
          x.payment_status === "PAID" &&
          x.payment_type === "CASH"
        )
        .sum("count");
      response[i.status].cardCount = list
        .filter(
          (x) =>
          x.status === i.status &&
          x.payment_status === "PAID" &&
          x.payment_type === "CARD"
        )
        .sum("count");
      response[i.status].privateDeliveryCount = list
        .filter((x) => x.status === i.status && x.is_private === true)
        .sum("count");
      response[i.status].unpaidDeliveryCount = list
        .filter((x) => x.status === i.status && x.payment_status === "UNPAID")
        .sum("count");
      response[i.status].paidDeliveryCount = list
        .filter((x) => x.status === i.status && x.payment_status === "PAID")
        .sum("count");
      response[i.status].failedDeliveryCount = list
        .filter(
          (x) =>
          x.status === i.status &&
          (x.payment_status === null ||
            x.payment_status === "FAILED" ||
            x.payment_status === "Failed" ||
            x.payment_status === undefined)
        )
        .sum("count");
    });

  return response;
};

/**
 * Function to get payout aggregate
 */
export const payoutSummaries = function (list = []) {
  Array.prototype.sum = function (prop) {
    var total = 0;
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop];
    }
    return total;
  };

  let totalCount = 0;
  let totalAmount = 0;
  let approvedPayout = 0;

  let response = {};

  console.log(list);

  totalCount = list.sum("count");
  totalAmount = list.sum("amount");
  approvedPayout = list.filter((x) => x.status === "Approved").length;

  response.totalCount = totalCount;
  response.totalAmount = totalAmount;
  response.approved = approvedPayout;

  return response;
};

/** Invert text color against background color*/
export const InvertColor = (hex = "fff") => {
  var text_color;

  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    // throw new Error('Invalid HEX color.');
    console.log(hex.length);
  }

  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  // http://stackoverflow.com/a/3943023/112731
  if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
    text_color = "#000000";
  } else {
    text_color = "#FFFFFF";
  }

  return text_color;
};

export function get_from_date() {
  const event = new Date();
  event.setHours(0, 0, 0);

  return DateToMs(event);
}

export function get_to_date() {
  const event = new Date();
  event.setHours(23, 59, 0);

  return DateToMs(event);
}

export function sort_by_timestamp(a, b) {
  if (a.time < b.time) {
    return -1;
  }
  if (a.time > b.time) {
    return 1;
  }
  return 0;
}

export const linear_distance = ({
    lat1,
    lng1
  }, {
    lat2,
    lng2
  },
  unit = "km"
) => {
  //harversine formula
  if (lat1 && lng1 && lat2 && lng2) {
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lng2 - lng1) * Math.PI) / 180;
    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    let d = R * c; // in metres
    if (unit === "km") {
      d = d / 1000;
    }
    return d;
  }
  return 0;
};

export function openInNewTab(url) {
  // console.log(url);
  var win = window.open(url, "_blank");
}

export async function getHubs(data) {
  var results = {};
  console.log(data);
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/extention/aggregation-center?manager=true`;
  console.log("Requesting: ", url);

  const response = await axios.post(url, data, {
    validateStatus: false,
  });
  console.log(response);

  if (response.data.code === 200) {
    results.success = true;
    results.message = response.data.message;
    results.data = response.data.responses;
  } else {
    results.success = false;
    results.message = response.data.message;
  }

  return results;
}